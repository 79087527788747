/* eslint-disable no-use-before-define */ // allow "private" funcs at bottom

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import IndexOrderLinks from '../../shared/index_pages/IndexOrderLinks.jsx'
import IndexTable from '../../shared/index_pages/IndexTable.jsx'
import LinkSharingForm from '../../shared/link_sharing/LinkSharingForm.jsx'
import PaginationLinks from '../../shared/index_pages/PaginationLinks.jsx'
import {
  IndexState,
  useIndexStateSetup,
  perPage,
} from '../../shared/index_pages/IndexState.ts'
import IndexFilters from '../../shared/index_pages/IndexFilters.tsx'
import { fetchResultsFromServer } from '../../shared/index_pages/fetch_results_from_server.js'
import ServerErrorMessage from '../../shared/index_pages/ServerErrorMessage.jsx'
import { useIndexFilters } from '../../shared/useIndexFilters.ts'
import { usePushUrlHistory } from '../../shared/usePushUrlHistory.js'
import {
  navigatorSupportOrgTopFilters,
  orderItemsConstructor,
  filterDrawerConstructor,
} from './navigator_support_org_index_setup.jsx'
import { PrivateIndicator } from '../../shared/PrivateIndicator.jsx'
import { RuleToFrom } from './RuleToFrom.jsx'
import './NavigatorSupportOrgIndex.scss'

const recordType = 'NavigatorSupportOrg'
const pageTitle = 'Support Organizations'
const privateRecordTooltipText =
  'This is a private organization and cannot be shared'

const ColStatus = ({ org }) => (
  <>
    <div className={`status-pill ${org.display_status.toLowerCase()}`}>
      {org.display_status}
    </div>
    {/^paused/i.test(org.display_status) && (
      <>
        {org.paused_services_resume_on && (
          <div>
            until {new Date(org.paused_services_resume_on).toLocaleDateString()}
          </div>
        )}
        {org.website_paused_note_en && <div>{org.website_paused_note_en}</div>}
      </>
    )}
  </>
)

const ColEligibility = ({ org, bannedStates, clinic_names }) => (
  <>
    {org.rules.map((rule) => (
      <RuleToFrom
        bannedStates={bannedStates}
        rule={{ ...rule, supportOrgId: org.id }}
        key={rule.id}
        clinic_names={clinic_names}
      />
    ))}
  </>
)

const NavigatorSupportOrgIndex = ({
  all_orgs,
  authenticated_api_send_to_phone_path,
  banned_states,
  partner_clinics,
  filter_drawer_options,
  initial_filters,
  initial_page,
  initial_order,
  on_starred_page = false,
  states_with_no_active_clinics,
}) => {
  // NOTE: filter drawers should now use the predicates (keys):
  // { name_eq_any: ['array', 'of', 'strings'], with_any_offerings: ['array', 'of', 'offering_keys'] }

  const [indexState, setIndexState] = IndexState.use({
    hashOfRecords: all_orgs,
    orderItems: orderItemsConstructor({
      hashOfRecords: all_orgs,
      appliedFilters: initial_filters,
    }),
    currentPage: initial_page,
    currentOrder: initial_order,
    recordType,
    tieBreakerFunc: (id) => all_orgs[id].name,
  })

  const handleApplyFilters = async (
    newFilters,
    _prevFilters,
    isFirstRender = false
  ) => {
    try {
      const serverData = await fetchResultsFromServer(
        indexState.baseUrl(),
        newFilters
      )
      setIndexState(IndexState.handleServerData(serverData, isFirstRender))
      setIndexState(
        IndexState.setOrderItems(
          orderItemsConstructor({
            hashOfRecords: all_orgs,
            appliedFilters: newFilters,
          })
        )
      )
      if (!isFirstRender) pushUrlHistory({ q: newFilters })
    } catch (error) {
      setIndexState(IndexState.setErrorMessage(error.message))
    }
  }

  const {
    appliedFilters,
    applyFilters,
    removeFilter,
    setUnappliedFilters,
    unappliedFilters,
  } = useIndexFilters(initial_filters, handleApplyFilters)

  const handleSetUnappliedFilters = (newFilters) => {
    const modifiedNewFilters = { ...newFilters }

    if (
      newFilters['from_state_filter'] &&
      !banned_states?.includes(newFilters['from_state_filter']) &&
      !newFilters['to_state_filter'] &&
      !statesWithNoActiveClinics.has(newFilters['from_state_filter'])
    ) {
      modifiedNewFilters['to_state_filter'] =
        modifiedNewFilters['from_state_filter']
    }

    if (!modifiedNewFilters['from_state_filter']) {
      delete modifiedNewFilters.to_state_filter
    }

    if (!modifiedNewFilters['to_state_filter']) {
      delete modifiedNewFilters.provider_filter
    }

    if (
      unappliedFilters['to_state_filter'] !==
      modifiedNewFilters['to_state_filter']
    ) {
      delete modifiedNewFilters.provider_filter
    }

    setUnappliedFilters(modifiedNewFilters)
  }

  const handleRemoveFilter = (key) => {
    const keys = [key]

    if (key === 'to_state_filter') {
      keys.push('provider_filter')
    }

    if (key === 'from_state_filter') {
      keys.push('to_state_filter')
      keys.push('provider_filter')
    }
    removeFilter(keys)
  }

  const recordOptionsForLinkSharingForm = useMemo(
    () => Object.values(all_orgs).map((org) => [org.name, org.id]),
    [all_orgs]
  )

  const pushUrlHistory = usePushUrlHistory(
    indexState.baseUrl(),
    {
      q: appliedFilters,
      page: indexState.currentPage,
      order: indexState.currentOrder,
      // scope: indexState.currentScope,
    },
    { disabled: on_starred_page }
  )

  useIndexStateSetup(indexState)

  const statesWithNoActiveClinics = new Set(states_with_no_active_clinics)

  const bannedStates = new Set(banned_states)
  const tableColumns = [
    {
      header: 'Support Organization Name',
      cssClass: 'col-name',
      render: (org) => {
        const link = (
          <a href={`/navigator_support_orgs/${org.id}`}>{org.name}</a>
        )

        return org.private_record === true ? (
          <PrivateIndicator clinic={org} dataType="supportOrgName">
            {link}
          </PrivateIndicator>
        ) : (
          link
        )
      },
      testId: 'col-org-name',
    },
    {
      header: (
        <div>
          <span>Eligibility: From</span>
          <span>Eligibility: To</span>
        </div>
      ),
      cssClass:
        'col-div_span_eligibility_from_span_span_eligibility_to_span_div',
      render: (org) => (
        <ColEligibility
          org={org}
          bannedStates={bannedStates}
          clinic_names={Object.fromEntries(
            partner_clinics.map((p) => [p.id, p.formatted_name])
          )}
        />
      ),
    },
    {
      header: 'Website',
      cssClass: 'col-website',
      render: (org) => (org.website ? <a href={org.website}>website</a> : '-'),
    },
    {
      header: 'Type',
      cssClass: 'col-type',
      render: (org) => org.org_type,
    },
    {
      header: 'Can Seekers Contact?',
      cssClass: 'col-contact',
      render: (org) => {
        let result = ''

        if (
          org.abortion_seeker_contact_preferences === 'allowed' ||
          org.abortion_seeker_contact_preferences === 'sometimes_allowed'
        ) {
          result = 'Yes'
        } else if (org.abortion_seeker_contact_preferences === 'not_allowed') {
          result = 'No'
        } else {
          result = ''
        }

        return result
      },
    },
    {
      header: (
        <>
          <i>Navigate</i> <span>Users</span>
        </>
      ),
      cssClass: 'col-navigate-users',
      render: (org) =>
        org.profile_ids.length ? (
          <a href={`/navigator_support_orgs/${org.id}#tab_navigate_users`}>Y</a>
        ) : (
          '-'
        ),
    },
    {
      header: 'Status',
      cssClass: 'col-status',
      render: (org) => <ColStatus org={org} />,
    },
  ]

  return (
    <div id="NavigatorSupportOrgIndex">
      {on_starred_page ? <h2> {pageTitle} </h2> : <h1> {pageTitle} </h1>}
      {indexState.errorMessage && (
        <ServerErrorMessage
          clearFilters={() => applyFilters({})}
          filterQueryParams={indexState.filterQueryParams}
          message={indexState.errorMessage}
        />
      )}
      {!on_starred_page && (
        <IndexFilters
          applyFilters={applyFilters}
          currentFiltersFromServer={indexState.filterDescriptions}
          currentPage={indexState.currentPage}
          drawerFilters={filterDrawerConstructor(
            filter_drawer_options,
            all_orgs
          )}
          filterDrawerOptions={filter_drawer_options}
          appliedFilters={appliedFilters}
          removeFilter={handleRemoveFilter}
          setUnappliedFilters={handleSetUnappliedFilters}
          unappliedFilters={unappliedFilters}
          topFilters={navigatorSupportOrgTopFilters(
            partner_clinics,
            statesWithNoActiveClinics,
            unappliedFilters
          )}
        />
      )}

      {indexState.paginatedOrderedScopedFilteredIds.size > 0 && (
        <div className="scopes-toggles-widgets-sorting">
          <div className="widgets">
            {!on_starred_page && authenticated_api_send_to_phone_path && (
              <LinkSharingForm
                authenticated_api_send_to_phone_path={
                  authenticated_api_send_to_phone_path
                }
                recordIds={[...indexState.selectedIds]}
                recordOptions={recordOptionsForLinkSharingForm}
                recordType={recordType}
              />
            )}
          </div>
          <div className="sorting">
            <IndexOrderLinks
              currentOrder={indexState.currentOrder}
              orderItems={indexState.orderItems}
              setCurrentOrder={(newOrder) => {
                setIndexState(IndexState.setOrder(newOrder))
                pushUrlHistory({ order: newOrder })
              }}
            />
          </div>
        </div>
      )}
      <div className="index-table-background">
        <IndexTable
          recordType={recordType}
          columns={tableColumns}
          hasSelectableColumn
          onStarredPage={on_starred_page}
          privateRecordTooltipText={privateRecordTooltipText}
          records={indexState.paginatedOrderedScopedFilteredRecords()}
          selectedIds={indexState.selectedIds}
          setSelectedIds={(set) =>
            setIndexState(IndexState.setSelectedIds(set))
          }
        />
      </div>
      <PaginationLinks
        currentPage={indexState.currentPage}
        onPageChange={(newPage) => {
          setIndexState(IndexState.setPage(newPage))
          pushUrlHistory({ page: newPage })
        }}
        perPage={perPage}
        recordType={recordType}
        totalRecords={indexState.totalRecords()}
      />
    </div>
  )
}

/* eslint-disable react/require-default-props */
NavigatorSupportOrgIndex.propTypes = {
  all_orgs: PropTypes.objectOf(PropTypes.shape({ id: PropTypes.number }))
    .isRequired,
  initial_order: PropTypes.string.isRequired,
  initial_page: PropTypes.number.isRequired,
  on_starred_page: PropTypes.bool,

  // send-to-phone related:
  authenticated_api_send_to_phone_path: PropTypes.string,

  // filter related:
  filter_drawer_options: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  initial_filters: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}
/* eslint-enable react/require-default-props */

export default NavigatorSupportOrgIndex
