import React from 'react'
import PropTypes from 'prop-types'
import AppointmentAvailabilityPills from './AppointmentAvailabilityPills.jsx'
import IndexTable from '../../shared/index_pages/IndexTable.jsx'
import {
  FullAddressColumn,
  PillLimitColumn,
  ProcedureLimitColumn,
  ExternalLink,
} from './navigator_clinic_snippets.jsx'
import './NavigatorClinicTable.scss'
import { PrivateIndicator } from '../../shared/PrivateIndicator.jsx'

const NavigatorClinicTable = ({
  appointment_availability_filter,
  records,
  scopeToUsStates,
  selectedIds,
  setSelectedIds,
  onStarredPage,
}) => {
  const columns = [
    {
      header: 'Provider Name',
      cssClass: 'col-name',
      render: (clinic) => {
        const link = (
          <a href={`/navigator_clinics/${clinic.id}`}>
            {clinic.formatted_name}
          </a>
        )

        return clinic.private_record === true ? (
          <PrivateIndicator dataType="providerName">{link}</PrivateIndicator>
        ) : (
          link
        )
      },
      testId: 'col-clinic-name',
    },
    {
      header: 'Full Address',
      cssClass: 'col-full-address',
      render: (clinic) => <FullAddressColumn clinic={clinic} />,
    },
    {
      header: 'State',
      cssClass: 'col-state',
      render: (clinic) => clinic.states_served.join(', '),
    },
    {
      header: 'Phone',
      cssClass: 'col-phone',
      render: (clinic) => (
        <ExternalLink
          href={clinic.formatted_phone ? `tel:${clinic.formatted_phone}` : null}
        />
      ),
    },
    {
      header: 'URL',
      cssClass: 'col-url',
      render: (clinic) => <ExternalLink href={clinic.url_en} />,
    },
    {
      header: 'Pill Limit',
      cssClass: 'col-pill-limit',
      render: (clinic) => (
        <PillLimitColumn
          clinic={clinic}
          json={clinic.pill_limit_json}
          scopeToUsStates={scopeToUsStates}
        />
      ),
      testId: 'pill-limit',
    },
    {
      header: 'Procedure Limit',
      cssClass: 'col-procedure-limit',
      render: (clinic) => (
        <ProcedureLimitColumn
          clinic={clinic}
          json={clinic.procedure_limit_json}
        />
      ),
    },
    {
      header: 'Appointment Availability',
      cssClass: 'col-appointment-availability',
      render: (clinic) => {
        const pills = (
          <AppointmentAvailabilityPills
            clinic={clinic}
            appointment_availability_filter={appointment_availability_filter}
          />
        )

        return !clinic.private_record &&
          clinic.abortion_days_protected === true ? (
          <PrivateIndicator dataType="weeklySchedule">{pills}</PrivateIndicator>
        ) : (
          pills
        )
      },
    },
  ]

  return (
    <div id="NavigatorClinicTable">
      <IndexTable
        recordType="NavigatorClinic"
        columns={columns}
        hasSelectableColumn
        onStarredPage={onStarredPage}
        privateRecordTooltipText="This is a private provider and cannot be shared"
        records={records}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
      />
    </div>
  )
}

const AirportPropType = PropTypes.shape({
  code: PropTypes.string.isRequired,
  miles: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
})

const AvailabilityTextPropType = PropTypes.shape({
  css_class: PropTypes.string,
  lower_range: PropTypes.string,
  text: PropTypes.string,
  upper_range: PropTypes.string,
})

// as the clinic object arrives from the database
export const clinicTypeBase = {
  appointment_availability_text_hash: PropTypes.objectOf(
    AvailabilityTextPropType
  ).isRequired,
  appointment_availability_verified_at: PropTypes.string,
  formatted_name: PropTypes.string.isRequired,
  formatted_phone: PropTypes.string,
  full_address_with_private_address_or_virtual_only:
    PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  lat: PropTypes.string,
  lng: PropTypes.string,
  location_type: PropTypes.string.isRequired,
  nearby_airports: PropTypes.arrayOf(AirportPropType).isRequired,
  pill_limit_json: PropTypes.objectOf(PropTypes.string).isRequired,
  private_record: PropTypes.bool.isRequired,
  procedure_limit_json: PropTypes.shape({
    limit: PropTypes.string.isRequired,
    exceptions: PropTypes.string,
  }).isRequired,
  states_served: PropTypes.arrayOf(PropTypes.string).isRequired,
  telehealth_limit_sort_json: PropTypes.objectOf(PropTypes.number).isRequired,
  url_en: PropTypes.string,
  driveTime: PropTypes.string,
  distance: PropTypes.string,
}

export const clinicType = PropTypes.shape(clinicTypeBase)

NavigatorClinicTable.propTypes = {
  records: PropTypes.arrayOf(clinicType).isRequired,
  scopeToUsStates: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIds: PropTypes.objectOf(PropTypes.bool).isRequired,
  setSelectedIds: PropTypes.func.isRequired,
  onStarredPage: PropTypes.bool.isRequired,
}

export default NavigatorClinicTable
