import React from 'react'
import infoIcon from '../../assets/images/info-icon.svg'
import { TooltipAnchor, Tooltip } from './Tooltip.jsx'

export const PrivateIndicator = ({ dataType, children, galType }) => {
  const TooltipTextMap = {
    fullAddress: (
      <>
        <strong>Private:</strong> This provider address displays privately on
        Navigate only and does not publicly display on the AbortionFinder
        platform; please use your discretion when sharing with abortion seekers.
      </>
    ),
    gestationalLimit: (
      <>
        <strong>Private:</strong> This provider's gestational limit for{' '}
        {galType} displays privately on Navigate only and will not publicly
        display on the AbortionFinder platform; please use your discretion when
        sharing with abortion seekers.
      </>
    ),
    pillAndProcedureLimit: (
      <>
        <strong>Private:</strong> This provider's gestational limits display
        privately on Navigate only and will not publicly display on the
        AbortionFinder platform; please use your discretion when sharing with
        abortion seekers.
      </>
    ),
    providerName: (
      <>
        <strong>Private:</strong> This provider profile displays privately on
        Navigate only and does not publicly display on the AbortionFinder
        platform; please use your discretion when sharing with abortion seekers.
        Some Navigate features are not available for private providers (e.g.,
        "Send to Phone").
      </>
    ),
    supportOrgName: (
      <>
        <strong>Private:</strong> This support org profile displays privately on
        Navigate only and does not publicly display on the AbortionFinder
        platform. Some Navigate features are not available for private support
        orgs (e.g., "Send to Phone").
      </>
    ),
    weeklySchedule: (
      <>
        <strong>Private:</strong> This provider's weekly schedule for abortion
        displays privately on Navigate only and will not publicly display on the
        AbortionFinder platform; please use your discretion when sharing with
        abortion seekers.
      </>
    ),
  }
  const tooltipText = TooltipTextMap[dataType]

  const labelMap = {
    fullAddress: 'Private',
    gestationalLimit: 'This gestational age limit is private.',
    pillAndProcedureLimit: 'Private',
    providerName: 'Private',
    weeklySchedule: 'This weekly schedule is private.',
  }
  const label = labelMap[dataType]

  return (
    <div>
      <div className="PrivateIndicator">
        <TooltipAnchor>
          Private
          <img src={infoIcon} alt="Private info icon" className="info-icon" />
          <Tooltip>{tooltipText}</Tooltip>
        </TooltipAnchor>
      </div>
      {children}
    </div>
  )
}
